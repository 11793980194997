import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { InventoryRecordMeta } from '@ts/inventory/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-inventory-record-meta-table-body-ui',
  templateUrl: './inventory-record-meta-table-body-ui.component.html',
  styleUrls: ['./inventory-record-meta-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMetaTableBodyUiComponent {
  @Input() inventoryRecordMetas!: readonly InventoryRecordMeta[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<
    ActionTemplateContext<InventoryRecordMeta>
  >;
}
