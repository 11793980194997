import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FacilityZoneUiMentionModule } from '@ts/facility/zone/ui-mention';

import { InventoryRecordMentionUiComponent } from './inventory-record-mention-ui/inventory-record-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, FacilityZoneUiMentionModule],
  declarations: [InventoryRecordMentionUiComponent],
  exports: [InventoryRecordMentionUiComponent],
})
export class InventoryRecordUiMentionModule {}
