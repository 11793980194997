import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PurchasingPagingService } from '@ts/purchasing/list/data-access';
import { Purchasing } from '@ts/purchasing/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-purchasing-list-infinite',
  templateUrl: './purchasing-list-infinite.component.html',
  styleUrls: ['./purchasing-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Purchasing>>;

  constructor(public purchasingPagingService: PurchasingPagingService) {}
}
