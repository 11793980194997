import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { InventoryRecordMetaSelectModalComponent } from '@ts/inventory/record/meta/action/feature-select';
import { InventoryRecordMeta } from '@ts/inventory/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-inventory-record-meta-form-field',
  templateUrl: './inventory-record-meta-form-field.component.html',
  styleUrls: ['./inventory-record-meta-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMetaFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'inventory_record_meta';
  initial?: InventoryRecordMeta;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<InventoryRecordMeta>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: InventoryRecordMetaSelectModalComponent,
        componentProps: { initial: entity },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
