import { Category } from '@ts/category/shared/util-core';
import { PromoProductLine } from '@ts/promo/shared/util-product-line';
import {
  HasDateCreatedTimestamp,
  HasDateEditedTimestamp,
} from '@ts/shared/date-time/util-core';
import { HasIsActive } from '@ts/shared/is-active/util-core';
import { Money } from '@ts/shared/money/util-core';

import { InventoryGeneric } from './inventory-online.model';
import { ProductAttributeValue } from './product-attribute.model';

interface ProductPriceSellBase {
  facility: number;
  price: Money;
  promo_product_line_active: PromoProductLine | null;
}

export type ProductPriceSell = Readonly<ProductPriceSellBase>;

interface ProductBase extends HasIsActive {
  id: number;
  slug: string;
  upc: string;
  title: string;
  thumbnail_url: string | null;
  inventory_generic: InventoryGeneric;

  /**
   * Whether an age wall is required to view this product
   */
  age_walled: boolean;

  /**
   * Lower bound estimate on the number of this product that has been purchased
   * E.g., 10 means "10 or more"
   */
  num_purchases_estimate: number;
  is_offline_only: boolean;

  price_sell: ProductPriceSell | null;
}

export type Product = Readonly<ProductBase>;

interface ProductMetaBase
  extends HasDateCreatedTimestamp,
    HasDateEditedTimestamp {
  id: number;
  description: string;
  attribute_values: readonly ProductAttributeValue[];
  main_category: Category;
}

export type ProductMeta = Readonly<ProductMetaBase>;

/******************************************
 * STAFF RELATED MODELS
 ******************************************/

export const PRODUCT_UPDATE_STAFF_UPC_AUTOMATIC = '#AUTOMATIC';

interface ProductUpdateStaffBase {
  id: number;
  title: string;

  // for create, this can also be '#AUTOMATIC'
  upc: string;
  description: string;
  main_category: number;
  attribute_values: readonly ProductAttributeValue[];
}

export type ProductUpdateStaff = Readonly<ProductUpdateStaffBase>;

interface ProductStaffBase
  extends ProductBase,
    HasDateCreatedTimestamp,
    HasDateEditedTimestamp {
  main_category: Category;
}

export type ProductStaff = Readonly<ProductStaffBase>;
