import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { OrderSelectStaffModalComponent } from '@ts/order/action/feature-select-staff';
import { Order } from '@ts/order/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-order-form-field-staff',
  templateUrl: './order-form-field-staff.component.html',
  styleUrls: ['./order-form-field-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormFieldStaffComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'order';
  initial?: Order;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<Order>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: OrderSelectStaffModalComponent,
        componentProps: { initial: entity },
        size: 'fit-content',
        backdropDismiss: false,
      };
    };
}
