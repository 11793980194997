import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HasGuid } from '@ts/shared/guid/util-core';

@Component({
  selector: 'ts-guid-link',
  templateUrl: './guid-link.component.html',
  styleUrls: ['./guid-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuidLinkComponent<T extends HasGuid> {
  @Input() instance!: T;
}
