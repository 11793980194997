import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedSearchbarUiCoreModule } from '@ts/shared/searchbar/ui-core';

import { SearchbarComponent } from './searchbar/searchbar.component';
import { SearchbarQueryParamComponent } from './searchbar-query-param/searchbar-query-param.component';

@NgModule({
  imports: [CommonModule, IonicModule, SharedSearchbarUiCoreModule],
  declarations: [SearchbarComponent, SearchbarQueryParamComponent],
  exports: [SearchbarComponent, SearchbarQueryParamComponent],
})
export class SharedSearchbarFeatureCoreModule {}
