import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { InventoryRecordUiMentionModule } from '@ts/inventory/record/ui-mention';

import { InventoryRecordMetaMentionUiComponent } from './inventory-record-meta-mention-ui/inventory-record-meta-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, InventoryRecordUiMentionModule],
  declarations: [InventoryRecordMetaMentionUiComponent],
  exports: [InventoryRecordMetaMentionUiComponent],
})
export class InventoryRecordMetaUiMentionModule {}
