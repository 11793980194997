<tr *ngFor="let inventoryRecordMeta of inventoryRecordMetas">
  <td>
    <div>
      <!--TODO(ivan): make inventory record mention ui for now placeholder -->
      {{ inventoryRecordMeta.inventory_record.product.title }}
    </div>
    <ts-date-created-updated-ui
      [instance]="inventoryRecordMeta"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div>low: {{ inventoryRecordMeta.quantity_threshold_low }}</div>
    <div>high: {{ inventoryRecordMeta.quantity_threshold_high }}</div>
  </td>
  <td>
    <div>
      Multiplier:
      {{ inventoryRecordMeta.inventory_replenishment_quantity_multiplier }}
    </div>
    <div>
      Unit of Measure:
      {{
        inventoryRecordMeta.inventory_replenishment_quantity_multiplier_unit_of_measure
      }}
    </div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: inventoryRecordMeta }"
    >
    </ng-template>
  </td>
</tr>
