import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { InventoryRecordMeta } from '@ts/inventory/shared/util-core';

@Component({
  selector: 'ts-inventory-record-meta-select-modal',
  templateUrl: './inventory-record-meta-select-modal.component.html',
  styleUrls: ['./inventory-record-meta-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMetaSelectModalComponent {
  @Input() initial?: InventoryRecordMeta;

  constructor(private modalController: ModalController) {}

  inventoryRecordMetaSelected(inventoryRecordMeta: InventoryRecordMeta | null) {
    this.modalController.dismiss({ inventoryRecordMeta });
  }
}
