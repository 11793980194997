import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InventoryRecord } from '@ts/inventory/shared/util-core';

@Component({
  selector: 'ts-inventory-record-mention-ui',
  templateUrl: './inventory-record-mention-ui.component.html',
  styleUrls: ['./inventory-record-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMentionUiComponent {
  @Input() inventoryRecord!: InventoryRecord;
}
