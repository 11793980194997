import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { Purchasing } from '@ts/purchasing/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-purchasing-table-ui',
  templateUrl: './purchasing-table-ui.component.html',
  styleUrls: ['./purchasing-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingTableUiComponent {
  @Input() purchasings!: readonly Purchasing[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<Purchasing>
  >;
}
