import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { Product } from '@ts/product/shared/util-core';
import {
  PromoProductLineDiscount,
  PromoProductLineLimit,
} from '@ts/promo/shared/util-product-line';
import { Money, MoneyOperationService } from '@ts/shared/money/util-core';
import {
  PriceDiscountInfo,
  PriceDiscountService,
} from '@ts/shared/price/util-core';

@Component({
  selector: 'ts-product-price-ui',
  templateUrl: './product-price-ui.component.html',
  styleUrls: ['./product-price-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPriceUiComponent implements OnChanges {
  @Input() product!: Product;

  // This input only matter when product has promoProductLineDiscount
  @Input() displayType!: 'inline' | 'table';

  price?: Money;
  priceDiscountInfo?: PriceDiscountInfo;
  promoProductLineDiscount?: PromoProductLineDiscount;
  promoProductLineLimit?: PromoProductLineLimit;
  priceZero!: boolean;

  constructor(
    private priceDiscountService: PriceDiscountService,
    private moneyOperationService: MoneyOperationService,
  ) {}

  ngOnChanges() {
    this.priceZero = this.moneyOperationService.isMoneyZero(
      this.product.price_sell?.price || { amount: '0.00', currency: 'IDR' },
    );

    this.price = this.product.price_sell?.price;

    const promoProductLine = this.product.price_sell?.promo_product_line_active;

    this.promoProductLineDiscount = undefined;
    this.priceDiscountInfo = undefined;
    this.promoProductLineLimit = undefined;

    if (this.price && promoProductLine) {
      switch (promoProductLine.type) {
        case 'discount': {
          this.promoProductLineDiscount = promoProductLine;
          this.priceDiscountInfo = this.priceDiscountService.calculate({
            price: this.price,
            discount: promoProductLine.discount_amount,
            discountFraction: promoProductLine.discount_fraction,
          });
          break;
        }
        case 'limit': {
          this.promoProductLineLimit = promoProductLine;
          break;
        }
      }
    }
  }
}
