import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InventoryRecordMeta } from '@ts/inventory/shared/util-core';

@Component({
  selector: 'ts-inventory-record-meta-mention-ui',
  templateUrl: './inventory-record-meta-mention-ui.component.html',
  styleUrls: ['./inventory-record-meta-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMetaMentionUiComponent {
  @Input() inventoryRecordMeta!: InventoryRecordMeta;
}
