import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';
import { SharedGuidUiCoreModule } from '@ts/shared/guid/ui-core';

import { PurchasingFormFieldComponent } from './purchasing-form-field/purchasing-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'purchasing',
          component: PurchasingFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    SharedGuidUiCoreModule,
  ],
  declarations: [PurchasingFormFieldComponent],
  exports: [PurchasingFormFieldComponent],
})
export class PurchasingFormFeatureFieldModule {}
