import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Purchasing } from '@ts/purchasing/shared/util-core';

@Component({
  selector: 'ts-purchasing-select-modal',
  templateUrl: './purchasing-select-modal.component.html',
  styleUrls: ['./purchasing-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSelectModalComponent {
  @Input() initial?: Purchasing;

  constructor(private modalController: ModalController) {}

  purchasingSelected(purchasing: Purchasing | null) {
    this.modalController.dismiss({ purchasing });
  }
}
