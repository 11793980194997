<ts-pagination-infinite-table [pagingService]="purchasingPagingService">
  <ts-purchasing-table-header-ui slot="header"></ts-purchasing-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-purchasing-table-body-ui
      [purchasings]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-purchasing-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
