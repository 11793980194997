import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { InventoryRecordMeta } from '@ts/inventory/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import {
  ApiEntityPagingAbstractService,
  EntityPagingService,
} from '@ts/shared/pagination/data-access-entity';

@Injectable({
  providedIn: 'root',
})
export class InventoryRecordMetaPagingService extends ApiEntityPagingAbstractService<InventoryRecordMeta> {
  relativeUrl$ = of('api/inventory/record/staff/meta-search');

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    protected override entityPagingService: EntityPagingService,
  ) {
    super();
  }
}
