import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PriceSellChangeMentionUiComponent } from './price-sell-change-mention-ui/price-sell-change-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PriceSellChangeMentionUiComponent],
  exports: [PriceSellChangeMentionUiComponent],
})
export class PriceSellChangeUiMentionModule {}
