import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PricePurchaseChangeSelectModalComponent } from '@ts/price/purchase/change/action/feature-select';
import { PricePurchaseChange } from '@ts/price/purchase/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-price-purchase-change-form-field',
  templateUrl: './price-purchase-change-form-field.component.html',
  styleUrls: ['./price-purchase-change-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseChangeFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'pricePurchaseChange';
  initial?: PricePurchaseChange;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<PricePurchaseChange>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PricePurchaseChangeSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
