import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedFormUiSubmitButtonModule } from '@ts/shared/form/ui-submit-button';

import { SearchbarUiComponent } from './searchbar-ui/searchbar-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    SharedFormUiSubmitButtonModule,
    TranslocoModule,
  ],
  declarations: [SearchbarUiComponent],
  exports: [SearchbarUiComponent],
})
export class SharedSearchbarUiCoreModule {}
