import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PriceSellChangePagingService } from '@ts/price/sell/change/list/data-access';
import { PriceSellChange } from '@ts/price/sell/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-price-sell-change-list-infinite',
  templateUrl: './price-sell-change-list-infinite.component.html',
  styleUrls: ['./price-sell-change-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSellChangeListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<PriceSellChange>>;

  constructor(
    public priceSellChangePagingService: PriceSellChangePagingService,
  ) {}
}
