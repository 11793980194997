import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { PricePurchaseChangeUiMentionModule } from '@ts/price/purchase/change/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { PricePurchaseChangeFormFieldComponent } from './price-purchase-change-form-field/price-purchase-change-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'price-purchase-change',
          component: PricePurchaseChangeFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    PricePurchaseChangeUiMentionModule,
  ],
  declarations: [PricePurchaseChangeFormFieldComponent],
  exports: [PricePurchaseChangeFormFieldComponent],
})
export class PricePurchaseChangeFormFeatureFieldModule {}
